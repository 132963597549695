var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-0 py-0 print-fab"},[(!_vm.responsive)?_c('div',{staticClass:"float-right d-flex flex-row toggle-crud",class:{
      'active pl-2 ': _vm.open,
      'active no-crud': !_vm.visibility_crud,
    }},[[_vm._t("default")]],2):_c('div',{staticClass:"float-right d-flex flex-row toggle-crud",class:{
      'active pl-2 ': _vm.open,
      'active no-crud': !_vm.visibility_crud,
    }},[(_vm.open || !_vm.visibility_crud)?[_vm._t("default")]:_vm._e(),(_vm.visibility_crud)?_c('b-button',{staticClass:"custom-button",attrs:{"size":"sm","variant":"none"},on:{"click":function($event){_vm.open = !_vm.open}}},[_c('b-icon-three-dots')],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }